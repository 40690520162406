import { LoadingContainer, MediumCard } from '@amstudio/react-native-components'
import { ProfilePicture } from '@/features/users/components/ProfilePicture'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '@/features/design/components/EmptyState'
import { useTheme } from '@amstudio/react-native-config'
import { useMessages } from '../hooks/useMessages'
import { useSendMessage } from '../hooks/useSendMessage'
import ContentLoader from 'react-content-loader/native'
import { Circle, Rect } from 'react-native-svg'
import { Container, ListLayout } from '@amstudio/react-native-components'
import { NewMessageInput } from './NewMessageInput'
import { MessageCard } from './MessageCard'
import { View } from 'react-native'
import { Conversation } from '@/data/objects/conversation-objects'
import { formatTimestamp } from '@amstudio/app-utils'

interface ConversationDetailsModalProps {
  conversation: Conversation
}

export function ConversationDetails({
  conversation,
}: ConversationDetailsModalProps) {
  const { t } = useTranslation('locallife')
  const { colors } = useTheme()

  const { messages, isLoading, loadPreviousMessages, hasMoreToLoad } =
    useMessages(conversation.id)

  const { message, setMessage, sendMessage, coalescedMessages, isSending } =
    useSendMessage(conversation.id)

  return (
    <LoadingContainer
      isLoading={isLoading}
      loadedState={(messages) => (
        <>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottomWidth: 0.5,
              borderBottomColor: colors.greyscale.grey3,
            }}
          >
            <MediumCard
              title={conversation.title}
              icon={
                <ProfilePicture
                  source={
                    conversation.participants[0]?.user.profilePicture?.s75 || ''
                  }
                  firstName={conversation.participants[0]?.user.firstName}
                  lastName={conversation.participants[0]?.user.lastName}
                  size={35}
                />
              }
            />
          </View>
          <ListLayout
            inverted
            noBorders
            items={coalescedMessages(messages)}
            renderItem={(message, index, previousMessage) => {
              const showTimestamp =
                !previousMessage ||
                previousMessage.author.id !== message.author.id ||
                (new Date(previousMessage.createdAt).getTime() -
                  new Date(message.createdAt).getTime() >
                  5 * 60 * 1000 &&
                  formatTimestamp(previousMessage.createdAt) !==
                    formatTimestamp(message.createdAt))

              return (
                <MessageCard
                  message={message}
                  showTimestamp={showTimestamp}
                  isSending={message.messageId < 0}
                  style={{
                    marginBottom: showTimestamp ? 24 : 4,
                  }}
                />
              )
            }}
            keyExtractor={(message) => message.messageId.toString()}
            pagination={{
              isLoading,
              loadMore: loadPreviousMessages,
              hasMoreToLoad: hasMoreToLoad,
            }}
            style={{
              paddingRight: 15,
              marginBottom: 15,
            }}
          />
          <NewMessageInput
            message={message}
            setMessage={setMessage}
            onPressSend={sendMessage}
            isSending={isSending}
          />
        </>
      )}
      data={messages}
      emptyCheck={'nullish-only'}
      emptyState={
        <EmptyState title={t('conversations.conversation.stack.noMessages')} />
      }
      loadingState={
        <Container style={{ justifyContent: 'flex-end' }}>
          <ContentLoader
            viewBox="0 0 446 160"
            height={250}
            width={'100%'}
            backgroundColor={colors.loading.loading1}
            foregroundColor={colors.loading.loading2}
          >
            <Circle cx="19" cy="25" r="16" />
            <Rect x="39" y="12" rx="5" ry="5" width="220" height="10" />
            <Rect x="40" y="29" rx="5" ry="5" width="220" height="10" />
            <Circle cx="420" cy="71" r="16" />
            <Rect x="179" y="76" rx="5" ry="5" width="220" height="10" />
            <Rect x="179" y="58" rx="5" ry="5" width="220" height="10" />
            <Circle cx="21" cy="117" r="16" />
            <Rect x="45" y="104" rx="5" ry="5" width="220" height="10" />
            <Rect x="45" y="122" rx="5" ry="5" width="220" height="10" />
          </ContentLoader>
        </Container>
      }
    />
  )
}
