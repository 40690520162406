import { Conversation } from '@/data/objects/conversation-objects'
import { useTranslation } from 'react-i18next'
import { MediumCard, MediumCardProps } from '@amstudio/react-native-components'
import { ProfilePicture } from '@/features/users/components/ProfilePicture'

interface Props {
  conversation: Conversation
  onPress?: () => void
  backgroundColor?: MediumCardProps['backgroundColor']
  lastMessageClipLength?: number
}

export function ConversationCard({
  conversation,
  onPress,
  backgroundColor,
  lastMessageClipLength = 32,
}: Props) {
  const { t } = useTranslation('locallife')

  let lastMessage = conversation.lastMessage?.content?.replace(/\n/gm, ' ')

  if (!!lastMessage && lastMessage.length >= lastMessageClipLength) {
    lastMessage = lastMessage.slice(0, lastMessageClipLength - 3) + '...'
  }

  return (
    <MediumCard
      title={conversation.title}
      backgroundColor={backgroundColor}
      subtitle={lastMessage ?? t('conversations.conversation.card.noMessages')}
      timestamp={conversation.lastMessage?.createdAt}
      icon={
        <ProfilePicture
          userId={conversation.participants[0]?.user.id || 0}
          source={conversation.participants[0]?.user.profilePicture?.s75 || ''}
          firstName={conversation.participants[0]?.user.firstName}
          lastName={conversation.participants[0]?.user.lastName}
          size={36}
        />
      }
      onPress={onPress}
      pressableIcon={<></>}
      hoverAnimationEnabled
      titleVariant={'title5'}
    />
  )
}
